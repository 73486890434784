<template>
  <div class="get-otp">
    <form action=""
          @submit.prevent="getOtp">
      <div class="get-otp__title" v-if="registrationPopup.type === 'offer'">
        <p>
          {{ $t('enterPhoneNumber') }}
        </p>
      </div>
      <div class="get-otp__title" v-if="registrationPopup.type === 'jobs'">
        <p>
          {{ $t('jobsTitleLabel1') }}
        </p>
      </div>
      <phone-input :errors="errors"
                   @on-update="updatePhone($event)"
                   @valid="isValidPhone = $event"/>
      <div class="auth-button">
        <base-button
          loader-color="white"
          :progress="isLoading"
          :disabled="!isValidPhone">
          <span v-if="registrationPopup.type === 'offer' && offerData">
            {{ parseFloat(offerData.price) > 0 ? $t('payNow') : $t('register') }}
          </span>
          <span v-if="registrationPopup.type === 'jobs'">
            {{ $t('getMyCode') }}
          </span>
        </base-button>
      </div>
      <p class="footer-message" v-if="registrationPopup.type === 'jobs'">
        {{ $t('sendOtpMessage') }}
      </p>
    </form>
  </div>
</template>

<script>
import PhoneInput from '@/components/atoms/PhoneInput'
import BaseButton from '@/components/atoms/base-button/index.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'GetOptStep',
  data () {
    return {
      errors: null,
      phone: null,
      isValidPhone: false
    }
  },
  components: {
    PhoneInput,
    BaseButton
  },
  computed: {
    ...mapGetters({
      isLoading: 'auth/isLoading',
      offerData: 'auth/offerData',
      registrationPopup: 'auth/registrationPopup'
    })
  },
  methods: {
    ...mapActions({
      getOtpCode: 'auth/getOtp'
    }),
    updatePhone (phone) {
      this.phone = phone
      this.errors = null
    },
    getOtp () {
      this.getOtpCode({ phone: this.phone }).catch(error => {
        if (error.data.error_type === 'scheme') {
          this.$toasted.error(this.$t('errorPhoneInvalid'))
        }
      })
    }
  }
}
</script>

<style lang="scss">
.get-otp{
  border-radius: 4px;
  background: transparent;
  padding: 39px 15px 40px;
  @include respondTo(600px){
    padding: 20px 0;
  }
  &__title {
    font-size: 18px;
    line-height: 1.3;
    font-weight: 500;
    color: #3b3b3b;
    max-width: 320px;
    margin: 0 auto 30px;
    text-align: center;
    p{
      font-size: 18px;
      line-height: 1.3;
      font-weight: 500;
      color: #3b3b3b;
      margin-bottom: 10px;
    }
    @include respondTo(600px){
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 20px;
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
.phone-input .vue-tel-input{
  border: 1px solid #000;
}
.phone-input input.vti__input{
  border-left-color: black;
  color: black;
  &::placeholder{
    color: black;
  }
}
.phone-input .vti__selection .vti__country-code,
.auth-sidebar__title,
.auth-sidebar__description,
.auth-form__pin-label,
.auth-form__resend p{
  color: black;
}
.auth-form__pin-label{
  margin-bottom: 10px;
  display: block;
}
.phone-input .vti__dropdown-arrow{
  background-image: url('../../assets/img/svg/arrow-down-black.svg');
}
.phone-input .vue-tel-input:focus-within {
  box-shadow: none;
  border-color: #000;
}
.phone-input input:focus{
  border-color: black !important;
}
.auth-form input,
.auth-form__short input {
  border-color: black;
  color: black;
  font-size: 16px;
  font-family: $mont;
  font-weight: normal;
  &::placeholder{
    color: black;
    font-size: 16px;
    font-family: $mont;
    font-weight: normal;
    @include respondTo(600px){
      font-size: 14px;
    }
  }
}
.get-opt{
  @include respondTo(600px){
    padding: 20px 15px;
  }
}
p.footer-message{
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 10px;
  margin-top: 15px;
  text-align: center;
  @include respondTo(600px){
    font-size: 14px;
    line-height: 1.2;
  }
}
</style>
