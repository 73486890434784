import GetOptStep from '@/components/features/GetOptStep.vue'
import ConfirmOptStep from '@/components/features/ConfirmOptStep.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'Registration',
  data () {
    return {}
  },
  components: {
    GetOptStep,
    ConfirmOptStep
  },
  computed: {
    ...mapGetters({
      otpResponse: 'auth/otpResponse'
    })
  }
}
